import React, { FC } from "react"
import BackgroundImage from "gatsby-background-image"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {makeStyles} from "@material-ui/styles";
import NuliaLogo from "./icons/NuliaLogo";
import {Theme, useTheme} from "@material-ui/core";
import {FluidObject} from "gatsby-image";

const useStyles = makeStyles((theme: Theme) => ({
    banner: {
        backgroundColor: "#6d57a8",
        width: "100%",
        height: 351
    },
    bannerContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff"
    },
    bannerTitle: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 48,
        lineHeight: "96px",
        margin: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: 32,
            lineHeight: "48px"
        }
    },
    bannerSubtitle: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 20,
        letterSpacing: 0.25,
        lineHeight: "24px",
        margin: 0,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: "24px"
        }
    }
}))

interface IProps {
    desktopImage: FluidObject
    mobileImage: FluidObject
    alt: string
}
const Banner: FC<IProps> = ({ desktopImage, mobileImage, alt }) => {
    const theme = useTheme()
    const classes = useStyles(theme)
    const isMobile = useMediaQuery("(max-width:959px)")

    return (
        <div className={classes.banner}>
            <BackgroundImage
                Tag="div"
                fluid={isMobile ? mobileImage : desktopImage}
                alt={alt}
                durationFadeIn={250}
                style={{
                    height: "100%",
                    backgroundPosition: "top center",
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    position: "relative"
                }}
            >
                <div className={classes.bannerContent}>
                    <NuliaLogo />
                    <h4 className={classes.bannerTitle}>Welcome to Nulia</h4>
                    <h5 className={classes.bannerSubtitle}>The world that works</h5>
                </div>
            </BackgroundImage>
        </div>
    )
}

export default Banner
